import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
*,
*:before,
*:after {
  margin: 0px;
  border: none;
  padding: 0px;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  margin: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  position: relative;
  border: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
  display: none;
}

}

a {
  text-decoration: none;
  color: ${props => props.theme.highlight};
}
p{
  font-size: 18px;
}
`;
