//Dependencies
import React, { useState } from "react";
import GlobalStyles from "./GlobalStyles";
import styled, { ThemeProvider } from "styled-components";
import defaultTheme from "./components/themes/default";
import Menu from "./components/Menu";
// import FrontendDeveloper from "./components/SVGs/FrontendDeveloper";
import WebDeveloper from "./components/SVGs/WebDeveloper";
import About from "./components/About";
import Code from "./components/Code";
import Contact from "./components/Contact";
import TopButton from "./components/TopButton";

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  font-size: 1rem;
  margin: 0px;
  background: ${props => props.theme.dark};
  height: 100%;
`;
const Title = styled.h1`
  color: ${props => props.theme.highlight};
  margin: 100px 20px;
  font-size: 3rem;
  padding: 7px;
`;
const LandingSection = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 30vh;
  text-align: center;
`;

function App() {
  const [topButtonDisplay, setTopButtonDisplay] = useState(false);
  window.onscroll = function() {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setTopButtonDisplay(true);
    } else {
      setTopButtonDisplay(false);
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container>
        <GlobalStyles />
        <LandingSection id="start">
          <Title>Lena Kastenmeier</Title>
          <WebDeveloper />
          <Menu></Menu>
        </LandingSection>
        <About id="about" />
        <Code id="code" />
        <Contact id="contact" />
        <TopButton topButtonDisplay={topButtonDisplay} />
      </Container>
    </ThemeProvider>
  );
}

export default App;
