import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
from  {transform: rotate(0deg);}
to {transform:rotate(360deg);}   `;

const spinreverse = keyframes`
from  {transform: rotate(0deg);}
to {transform:rotate(-360deg);}   `;

const Animation = styled.svg`
  width: 25%;
  height: 30%;
  margin: 20px;
  @media (max-width: 650px) {
    margin: 10px;
  }

  path:nth-child(3) {
    stroke-dasharray: 1500px;
    stroke-dashoffset: 1500px;
    animation: ${spin} 15s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
  }
  path:nth-child(6) {
    stroke-dasharray: 1500px;
    stroke-dashoffset: 1500px;
    animation: ${spinreverse} 15s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
  }
`;

export default function CodeIcon() {
  return (
    <Animation
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="512px"
      height="512px"
    >
      <g id="Monitor_setting" data-name="Monitor setting">
        <path
          d="M57,10H54a1,1,0,0,0,0,2h3a3,3,0,0,1,3,3V47a3,3,0,0,1-3,3H7a3,3,0,0,1-3-3V15a3,3,0,0,1,3-3h3a1,1,0,0,0,0-2H7a5.006,5.006,0,0,0-5,5V47a5.006,5.006,0,0,0,5,5H25v4H18a3,3,0,0,0,0,6H46a3,3,0,0,0,0-6H39V52H57a5.006,5.006,0,0,0,5-5V15A5.006,5.006,0,0,0,57,10ZM47,59a1,1,0,0,1-1,1H18a1,1,0,0,1,0-2H46A1,1,0,0,1,47,59ZM37,56H27V52H37Z"
          fill="#FFD35F"
        />
        <path d="M31,47a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" fill="#FFD35F" />
        <path
          d="M34,21h1.756l-1.243,1.243a1,1,0,0,0,0,1.414l2.83,2.83a1,1,0,0,0,1.414,0L40,25.244V27a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V25.244l1.243,1.243a1,1,0,0,0,1.414,0l2.83-2.83a1,1,0,0,0,0-1.414L50.244,21H52a1,1,0,0,0,1-1V16a1,1,0,0,0-1-1H50.244l1.243-1.243a1,1,0,0,0,0-1.414l-2.83-2.83a1,1,0,0,0-1.414,0L46,10.756V9a1,1,0,0,0-1-1H41a1,1,0,0,0-1,1v1.756L38.757,9.513a1,1,0,0,0-1.414,0l-2.83,2.83a1,1,0,0,0,0,1.414L35.756,15H34a1,1,0,0,0-1,1v4A1,1,0,0,0,34,21Zm1-4h2.35a1,1,0,0,0,.958-.713,2.239,2.239,0,0,1,.167-.4,1,1,0,0,0-.178-1.173L36.634,13.05l1.416-1.416L39.713,13.3a1,1,0,0,0,1.171.179,2.262,2.262,0,0,1,.4-.168A1,1,0,0,0,42,12.35V10h2v2.35a1,1,0,0,0,.713.958,2.262,2.262,0,0,1,.4.168,1,1,0,0,0,1.171-.179l1.663-1.663,1.416,1.416L47.7,14.713a1,1,0,0,0-.179,1.171,2.262,2.262,0,0,1,.168.4A1,1,0,0,0,48.65,17H51v2H48.65a1,1,0,0,0-.958.713,2.262,2.262,0,0,1-.168.4,1,1,0,0,0,.179,1.171l1.663,1.663L47.95,24.366,46.287,22.7a1,1,0,0,0-1.171-.179,2.262,2.262,0,0,1-.4.168A1,1,0,0,0,44,23.65V26H42V23.65a1,1,0,0,0-.713-.958,2.262,2.262,0,0,1-.4-.168,1,1,0,0,0-1.171.179L38.05,24.366,36.634,22.95,38.3,21.287a1,1,0,0,0,.178-1.173,2.239,2.239,0,0,1-.167-.4A1,1,0,0,0,37.35,19H35Z"
          fill="#FFD35F"
        />
        <path
          d="M26,28v3.753c-.144.057-.283.114-.419.174l-2.654-2.654a1,1,0,0,0-1.414,0l-4.24,4.24a1,1,0,0,0,0,1.414l2.654,2.654c-.06.136-.117.275-.174.419H16a1,1,0,0,0-1,1v5H8V16h2a1,1,0,0,0,0-2H7a1,1,0,0,0-1,1V45a1,1,0,0,0,1,1H57a1,1,0,0,0,1-1V15a1,1,0,0,0-1-1H55a1,1,0,0,0,0,2h1V44H45V39a1,1,0,0,0-1-1H40.247c-.057-.144-.114-.283-.174-.419l2.654-2.654a1,1,0,0,0,0-1.414l-4.24-4.24a1.029,1.029,0,0,0-1.414,0l-2.654,2.654c-.136-.06-.275-.117-.419-.174V28a1,1,0,0,0-1-1H27A1,1,0,0,0,26,28Zm0,14a4,4,0,0,1,8,0,3.943,3.943,0,0,1-.544,2H26.544A3.943,3.943,0,0,1,26,42Zm2-9.54V29h4v3.46a1,1,0,0,0,.694.952,8.259,8.259,0,0,1,1.445.605,1,1,0,0,0,1.188-.17l2.453-2.453,2.826,2.826-2.453,2.453a1,1,0,0,0-.17,1.188,8.259,8.259,0,0,1,.605,1.445A1,1,0,0,0,39.54,40H43v4H35.654a6,6,0,1,0-11.308,0H17V40h3.46a1,1,0,0,0,.952-.694,8.259,8.259,0,0,1,.605-1.445,1,1,0,0,0-.17-1.188L19.394,34.22l2.826-2.826,2.453,2.453a1,1,0,0,0,1.188.17,8.259,8.259,0,0,1,1.445-.605A1,1,0,0,0,28,32.46Z"
          fill="#FFD35F"
        />
        <path
          d="M43,22a4,4,0,1,0-4-4A4,4,0,0,0,43,22Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,43,16Z"
          fill="#FFD35F"
        />
        <path
          d="M13,15h1.756l-1.243,1.243a1,1,0,0,0,0,1.414l2.83,2.83a1,1,0,0,0,1.414,0L19,19.244V21a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V19.244l1.243,1.243a1,1,0,0,0,1.414,0l2.83-2.83a1,1,0,0,0,0-1.414L29.244,15H31a1,1,0,0,0,1-1V10a1,1,0,0,0-1-1H29.244l1.243-1.243a1,1,0,0,0,0-1.414l-2.83-2.83a1,1,0,0,0-1.414,0L25,4.756V3a1,1,0,0,0-1-1H20a1,1,0,0,0-1,1V4.756L17.757,3.513a1,1,0,0,0-1.414,0l-2.83,2.83a1,1,0,0,0,0,1.414L14.756,9H13a1,1,0,0,0-1,1v4A1,1,0,0,0,13,15Zm1-4h2.35a1,1,0,0,0,.958-.713,2.239,2.239,0,0,1,.167-.4A1,1,0,0,0,17.3,8.713L15.634,7.05,17.05,5.634,18.713,7.3a1,1,0,0,0,1.171.179,2.262,2.262,0,0,1,.4-.168A1,1,0,0,0,21,6.35V4h2V6.35a1,1,0,0,0,.713.958,2.262,2.262,0,0,1,.4.168A1,1,0,0,0,25.287,7.3L26.95,5.634,28.366,7.05,26.7,8.713a1,1,0,0,0-.179,1.171,2.262,2.262,0,0,1,.168.4A1,1,0,0,0,27.65,11H30v2H27.65a1,1,0,0,0-.958.713,2.262,2.262,0,0,1-.168.4,1,1,0,0,0,.179,1.171l1.663,1.663L26.95,18.366,25.287,16.7a1,1,0,0,0-1.171-.179,2.262,2.262,0,0,1-.4.168A1,1,0,0,0,23,17.65V20H21V17.65a1,1,0,0,0-.713-.958,2.262,2.262,0,0,1-.4-.168,1,1,0,0,0-1.171.179L17.05,18.366,15.634,16.95,17.3,15.287a1,1,0,0,0,.178-1.173,2.239,2.239,0,0,1-.167-.4A1,1,0,0,0,16.35,13H14Z"
          fill="#FFD35F"
        />
        <path
          d="M22,16a4,4,0,1,0-4-4A4,4,0,0,0,22,16Zm0-6a2,2,0,1,1-2,2A2,2,0,0,1,22,10Z"
          fill="#FFD35F"
        />
      </g>
    </Animation>
  );
}
