import React from "react";
import styled from "styled-components";
import CodeIcon from "./SVGs/CodeIcon";
import Frame from "./Frame";
const Container = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  margin: auto;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  margin-bottom: 30%;
`;

export default function Code({ id }) {
  return (
    <>
      <Container id={id}>
        <Frame>
          <p>
            Trallergy is an application that allows you to generate
            warning-cards based on your allergy, in different languages. You can
            download them or save them to your favourites for easy access or
            offline use. Additionally, Trallergy shows you all hospitals closeby
            in case of an emergency.
            <br />
            <a href="https://trallergy.herokuapp.com">Live version</a>
            <br />
            <a href="https://github.com/lkastenmeier/allergy-translator">
              Source Code
            </a>
          </p>
        </Frame>
        <CodeIcon />
      </Container>
    </>
  );
}
