import React from "react";
import styled, { keyframes } from "styled-components";

const spin = keyframes`
from  {transform: rotate(0deg);}
to {transform:rotate(360deg);}   `;

const Animation = styled.svg`
  width: 25%;
  height: 30%;
  margin: 20px;
  @media (max-width: 650px) {
    margin: 10px;
  }

  path:nth-child(1) {
    stroke-dasharray: 1500px;
    stroke-dashoffset: 1500px;
    animation: ${spin} 50s infinite linear;
    transform-origin: center;
  }
`;

export default function AboutIcon() {
  return (
    <Animation
      xmlns="http://www.w3.org/2000/svg"
      height="512px"
      viewBox="0 0 512 512"
      width="512px"
    >
      <path
        d="m446.808594 136.847656 19-31.667968-58.988282-58.988282-31.667968 19c-21.09375-13.203125-44.023438-22.683594-68.398438-28.28125l-8.960937-36.910156h-83.585938l-8.960937 36.910156c-24.375 5.597656-47.304688 15.078125-68.398438 28.28125l-31.667968-19-58.988282 58.988282 19 31.667968c-13.203125 21.09375-22.683594 44.023438-28.28125 68.394532l-36.910156 8.964843v83.585938l36.910156 8.960937c5.597656 24.375 15.078125 47.304688 28.28125 68.394532l-19 31.667968 58.988282 58.992188 31.667968-19c21.09375 13.203125 44.023438 22.683594 68.394532 28.28125l8.964843 36.910156h83.585938l8.960937-36.910156c24.375-5.597656 47.304688-15.078125 68.398438-28.28125l31.664062 19 58.988282-58.992188-19-31.667968c13.203124-21.089844 22.6875-44.019532 28.285156-68.394532l36.910156-8.960937v-83.585938l-36.910156-8.960937c-5.597656-24.375-15.078125-47.304688-28.28125-68.398438zm35.191406 137.359375-32.648438 7.925781-1.769531 9.554688c-5.003906 27.023438-15.378906 52.125-30.839843 74.609375l-5.464844 7.945313 16.753906 27.921874-25.863281 25.863282-27.925781-16.753906c-5.222657 2.945312-33.421876 27.210937-82.554688 36.304687l-9.554688 1.769531-7.925781 32.652344h-36.414062l-7.925781-32.648438-9.554688-1.769531c-27.023438-5.003906-52.125-15.378906-74.609375-30.839843l-7.945313-5.464844-27.925781 16.753906-25.863281-25.863281 16.753906-27.925781c-2.945312-5.222657-27.207031-33.421876-36.304687-82.554688l-1.769531-9.554688-32.648438-7.925781v-36.414062l32.652344-7.925781c1.605468-5.785157 4.492187-43.277344 32.605468-84.164063l5.464844-7.949219-16.753906-27.921875 25.863281-25.863281 27.925781 16.753906c5.234376-2.953125 33.417969-27.207031 82.554688-36.304687l9.554688-1.769531 7.925781-32.648438h36.414062l7.925781 32.648438 9.554688 1.769531c49.183594 9.105469 77.300781 33.34375 82.554688 36.304687l27.925781-16.753906 25.863281 25.863281-16.753906 27.925781 5.464844 7.945313c28.144531 40.929687 31 78.371094 32.609374 84.164063l32.648438 7.925781zm0 0"
        fill="#FED362"
      />
      <path
        d="m256 91c-90.980469 0-165 74.019531-165 165s74.019531 165 165 165 165-74.019531 165-165-74.019531-165-165-165zm-74.621094 277.445312c3.300782-32.816406 27.835938-59.457031 59.621094-65.9375v58.492188h30v-58.492188c31.785156 6.480469 56.320312 33.121094 59.621094 65.9375-21.390625 14.242188-47.050782 22.554688-74.621094 22.554688s-53.230469-8.3125-74.621094-22.554688zm175.222656-22.527343c-12.964843-43.28125-53.15625-74.917969-100.601562-74.917969s-87.636719 31.636719-100.601562 74.917969c-21.378907-23.890625-34.398438-55.410157-34.398438-89.917969 0-74.4375 60.5625-135 135-135s135 60.5625 135 135c0 34.507812-13.019531 66.027344-34.398438 89.917969zm0 0"
        fill="#FED362"
      />
      <path
        d="m316 211c0-33.085938-26.914062-60-60-60s-60 26.914062-60 60 26.914062 60 60 60 60-26.914062 60-60zm-60 30c-16.542969 0-30-13.457031-30-30s13.457031-30 30-30 30 13.457031 30 30-13.457031 30-30 30zm0 0"
        fill="#FED362"
      />
    </Animation>
  );
}
