import React from "react";
import styled, { css } from "styled-components";

const Button = styled.a`
  /* display: none; */
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: 2px solid ${props => props.theme.highlight};
  border-radius: 10px 0px 10px 0px;
  outline: none;
  background-color: ${props => props.theme.highlight};
  color: ${props => props.theme.dark};
  cursor: pointer;
  padding: 10px;

  ${props =>
    props.display
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `};
`;

export default function TopButton({ topButtonDisplay }) {
  // const mybutton = createRef(null);

  return (
    <Button display={topButtonDisplay} href="#start">
      Top
    </Button>
  );
}
