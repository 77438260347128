import React from "react";
import styled from "styled-components";
import ContactIcon from "./SVGs/ContactIcon";
import EmailIcon from "./SVGs/EmailIcon";
import XingIcon from "./SVGs/XingIcon";
import LinkedinIcon from "./SVGs/LinkedinIcon";
import GithubIcon from "./SVGs/GithubIcon";
import Frame from "./Frame";
const Container = styled.div`
  display: flex;
  margin: auto;
  margin-bottom: 15vh;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 100%;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 10px;
`;
const ContactButton = styled.a`
  height: 60px;
  padding: 5px;
  margin: 20px;
`;

export default function Contact({ id }) {
  return (
    <>
      <Container id={id}>
        <ContactIcon />
        <Frame>
          <p>To get in touch you can contact me here:</p>
          <ContactContainer>
            <ContactButton href="mailto:l.<span><span/>kastenmeier<span><span/>@web.de">
              <EmailIcon />
            </ContactButton>
            <ContactButton href="https://www.linkedin.com/in/lena-kastenmeier/">
              <LinkedinIcon />
            </ContactButton>
            <ContactButton href="https://github.com/lkastenmeier">
              <GithubIcon />
            </ContactButton>
            <ContactButton href="https://www.xing.com/profile/Lena_Kastenmeier/cv">
              <XingIcon />
            </ContactButton>
          </ContactContainer>
        </Frame>
      </Container>
    </>
  );
}
