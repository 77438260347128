import React from "react";
import styled, { keyframes } from "styled-components";

const lineanim = keyframes`
to {
  stroke-dashoffset: 0;
}`;

const Animation = styled.svg`
  position: relative;
  margin: auto;
  top: 40%;
  left: 40%;
  width: 80vw;
  transform: translate(-50%, -40%);
  stroke-linecap: square;

  path:nth-child(1) {
    stroke-dasharray: 1500px;
    stroke-dashoffset: 1500px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(2) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(3) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(4) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(5) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(6) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(7) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(8) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(9) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(10) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(11) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(12) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(13) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(14) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(15) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
  path:nth-child(16) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards;
  }
  path:nth-child(17) {
    stroke-dasharray: 600px;
    stroke-dashoffset: 600px;
    animation: ${lineanim} 7s ease forwards 0.3s;
  }
`;

export default function WebDeveloper() {
  return (
    <Animation
      width="989"
      height="137"
      viewBox="0 0 989 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M124.89 9.38998L98.63 100H85.37L64.31 27.07L42.47 100L29.34 100.13L3.98999 9.38998H16.6L36.36 86.22L58.2 9.38998H71.46L92.26 85.96L112.15 9.38998H124.89Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M202.836 61.65C202.836 63.9033 202.706 66.2867 202.446 68.8H145.506C145.939 75.82 148.323 81.3233 152.656 85.31C157.076 89.21 162.406 91.16 168.646 91.16C173.759 91.16 178.006 89.99 181.386 87.65C184.853 85.2233 187.279 82.0167 188.666 78.03H201.406C199.499 84.8767 195.686 90.4667 189.966 94.8C184.246 99.0467 177.139 101.17 168.646 101.17C161.886 101.17 155.819 99.6533 150.446 96.62C145.159 93.5866 140.999 89.2967 137.966 83.75C134.933 78.1167 133.416 71.6167 133.416 64.25C133.416 56.8833 134.889 50.4267 137.836 44.88C140.783 39.3333 144.899 35.0867 150.186 32.14C155.559 29.1067 161.713 27.59 168.646 27.59C175.406 27.59 181.386 29.0633 186.586 32.01C191.786 34.9567 195.773 39.03 198.546 44.23C201.406 49.3433 202.836 55.15 202.836 61.65ZM190.616 59.18C190.616 54.6733 189.619 50.8166 187.626 47.61C185.633 44.3166 182.903 41.8467 179.436 40.2C176.056 38.4667 172.286 37.6 168.126 37.6C162.146 37.6 157.033 39.5067 152.786 43.32C148.626 47.1333 146.243 52.42 145.636 59.18H190.616Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M230.281 42.02C232.708 37.7733 236.261 34.3066 240.941 31.62C245.621 28.9333 250.951 27.59 256.931 27.59C263.345 27.59 269.108 29.1067 274.221 32.14C279.335 35.1733 283.365 39.4633 286.311 45.01C289.258 50.47 290.731 56.84 290.731 64.12C290.731 71.3133 289.258 77.7267 286.311 83.36C283.365 88.9933 279.291 93.37 274.091 96.49C268.978 99.61 263.258 101.17 256.931 101.17C250.778 101.17 245.361 99.8267 240.681 97.14C236.088 94.4533 232.621 91.03 230.281 86.87V100H218.451V3.79999H230.281V42.02ZM278.641 64.12C278.641 58.7466 277.558 54.0667 275.391 50.08C273.225 46.0933 270.278 43.06 266.551 40.98C262.911 38.9 258.881 37.86 254.461 37.86C250.128 37.86 246.098 38.9433 242.371 41.11C238.731 43.19 235.785 46.2667 233.531 50.34C231.365 54.3267 230.281 58.9633 230.281 64.25C230.281 69.6233 231.365 74.3466 233.531 78.42C235.785 82.4066 238.731 85.4833 242.371 87.65C246.098 89.73 250.128 90.77 254.461 90.77C258.881 90.77 262.911 89.73 266.551 87.65C270.278 85.4833 273.225 82.4066 275.391 78.42C277.558 74.3466 278.641 69.58 278.641 64.12Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M369.171 9.38998C379.051 9.38998 387.588 11.2533 394.781 14.98C402.061 18.62 407.608 23.8633 411.421 30.71C415.321 37.5566 417.271 45.6167 417.271 54.89C417.271 64.1633 415.321 72.2233 411.421 79.07C407.608 85.83 402.061 91.03 394.781 94.67C387.588 98.2233 379.051 100 369.171 100H340.961V9.38998H369.171ZM369.171 90.25C380.871 90.25 389.798 87.1733 395.951 81.02C402.105 74.78 405.181 66.07 405.181 54.89C405.181 43.6233 402.061 34.8267 395.821 28.5C389.668 22.1733 380.785 19.01 369.171 19.01H352.791V90.25H369.171Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M497.875 61.65C497.875 63.9033 497.745 66.2867 497.485 68.8H440.545C440.979 75.82 443.362 81.3233 447.695 85.31C452.115 89.21 457.445 91.16 463.685 91.16C468.799 91.16 473.045 89.99 476.425 87.65C479.892 85.2233 482.319 82.0167 483.705 78.03H496.445C494.539 84.8767 490.725 90.4667 485.005 94.8C479.285 99.0467 472.179 101.17 463.685 101.17C456.925 101.17 450.859 99.6533 445.485 96.62C440.199 93.5866 436.039 89.2967 433.005 83.75C429.972 78.1167 428.455 71.6167 428.455 64.25C428.455 56.8833 429.929 50.4267 432.875 44.88C435.822 39.3333 439.939 35.0867 445.225 32.14C450.599 29.1067 456.752 27.59 463.685 27.59C470.445 27.59 476.425 29.0633 481.625 32.01C486.825 34.9567 490.812 39.03 493.585 44.23C496.445 49.3433 497.875 55.15 497.875 61.65ZM485.655 59.18C485.655 54.6733 484.659 50.8166 482.665 47.61C480.672 44.3166 477.942 41.8467 474.475 40.2C471.095 38.4667 467.325 37.6 463.165 37.6C457.185 37.6 452.072 39.5067 447.825 43.32C443.665 47.1333 441.282 52.42 440.675 59.18H485.655Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M540.01 89.08L562.11 28.76H574.72L546.77 100H532.99L505.04 28.76H517.78L540.01 89.08Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M651.362 61.65C651.362 63.9033 651.232 66.2867 650.972 68.8H594.032C594.465 75.82 596.848 81.3233 601.182 85.31C605.602 89.21 610.932 91.16 617.172 91.16C622.285 91.16 626.532 89.99 629.912 87.65C633.378 85.2233 635.805 82.0167 637.192 78.03H649.932C648.025 84.8767 644.212 90.4667 638.492 94.8C632.772 99.0467 625.665 101.17 617.172 101.17C610.412 101.17 604.345 99.6533 598.972 96.62C593.685 93.5866 589.525 89.2967 586.492 83.75C583.458 78.1167 581.942 71.6167 581.942 64.25C581.942 56.8833 583.415 50.4267 586.362 44.88C589.308 39.3333 593.425 35.0867 598.712 32.14C604.085 29.1067 610.238 27.59 617.172 27.59C623.932 27.59 629.912 29.0633 635.112 32.01C640.312 34.9567 644.298 39.03 647.072 44.23C649.932 49.3433 651.362 55.15 651.362 61.65ZM639.142 59.18C639.142 54.6733 638.145 50.8166 636.152 47.61C634.158 44.3166 631.428 41.8467 627.962 40.2C624.582 38.4667 620.812 37.6 616.652 37.6C610.672 37.6 605.558 39.5067 601.312 43.32C597.152 47.1333 594.768 52.42 594.162 59.18H639.142Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M678.807 3.79999V100H666.977V3.79999H678.807Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M730.039 101.17C723.366 101.17 717.299 99.6533 711.839 96.62C706.466 93.5866 702.219 89.2967 699.099 83.75C696.066 78.1167 694.549 71.6167 694.549 64.25C694.549 56.97 696.109 50.5567 699.229 45.01C702.436 39.3767 706.769 35.0867 712.229 32.14C717.689 29.1067 723.799 27.59 730.559 27.59C737.319 27.59 743.429 29.1067 748.889 32.14C754.349 35.0867 758.639 39.3333 761.759 44.88C764.966 50.4267 766.569 56.8833 766.569 64.25C766.569 71.6167 764.922 78.1167 761.629 83.75C758.422 89.2967 754.046 93.5866 748.499 96.62C742.952 99.6533 736.799 101.17 730.039 101.17ZM730.039 90.77C734.286 90.77 738.272 89.7733 741.999 87.78C745.726 85.7867 748.716 82.7966 750.969 78.81C753.309 74.8233 754.479 69.97 754.479 64.25C754.479 58.53 753.352 53.6767 751.099 49.69C748.846 45.7033 745.899 42.7567 742.259 40.85C738.619 38.8567 734.676 37.86 730.429 37.86C726.096 37.86 722.109 38.8567 718.469 40.85C714.916 42.7567 712.056 45.7033 709.889 49.69C707.722 53.6767 706.639 58.53 706.639 64.25C706.639 70.0567 707.679 74.9533 709.759 78.94C711.926 82.9267 714.786 85.9167 718.339 87.91C721.892 89.8167 725.792 90.77 730.039 90.77Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M793.953 41.89C796.293 37.8167 799.76 34.4366 804.353 31.75C809.033 28.9766 814.45 27.59 820.603 27.59C826.93 27.59 832.65 29.1067 837.763 32.14C842.963 35.1733 847.037 39.4633 849.983 45.01C852.93 50.47 854.403 56.84 854.403 64.12C854.403 71.3133 852.93 77.7267 849.983 83.36C847.037 88.9933 842.963 93.37 837.763 96.49C832.65 99.61 826.93 101.17 820.603 101.17C814.537 101.17 809.163 99.8267 804.483 97.14C799.89 94.3667 796.38 90.9433 793.953 86.87V133.8H782.123V28.76H793.953V41.89ZM842.313 64.12C842.313 58.7466 841.23 54.0667 839.063 50.08C836.897 46.0933 833.95 43.06 830.223 40.98C826.583 38.9 822.553 37.86 818.133 37.86C813.8 37.86 809.77 38.9433 806.043 41.11C802.403 43.19 799.457 46.2667 797.203 50.34C795.037 54.3267 793.953 58.9633 793.953 64.25C793.953 69.6233 795.037 74.3466 797.203 78.42C799.457 82.4066 802.403 85.4833 806.043 87.65C809.77 89.73 813.8 90.77 818.133 90.77C822.553 90.77 826.583 89.73 830.223 87.65C833.95 85.4833 836.897 82.4066 839.063 78.42C841.23 74.3466 842.313 69.58 842.313 64.12Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M934.975 61.65C934.975 63.9033 934.845 66.2867 934.585 68.8H877.645C878.078 75.82 880.462 81.3233 884.795 85.31C889.215 89.21 894.545 91.16 900.785 91.16C905.898 91.16 910.145 89.99 913.525 87.65C916.992 85.2233 919.418 82.0167 920.805 78.03H933.545C931.638 84.8767 927.825 90.4667 922.105 94.8C916.385 99.0467 909.278 101.17 900.785 101.17C894.025 101.17 887.958 99.6533 882.585 96.62C877.298 93.5866 873.138 89.2967 870.105 83.75C867.072 78.1167 865.555 71.6167 865.555 64.25C865.555 56.8833 867.028 50.4267 869.975 44.88C872.922 39.3333 877.038 35.0867 882.325 32.14C887.698 29.1067 893.852 27.59 900.785 27.59C907.545 27.59 913.525 29.0633 918.725 32.01C923.925 34.9567 927.912 39.03 930.685 44.23C933.545 49.3433 934.975 55.15 934.975 61.65ZM922.755 59.18C922.755 54.6733 921.758 50.8166 919.765 47.61C917.771 44.3166 915.041 41.8467 911.575 40.2C908.195 38.4667 904.425 37.6 900.265 37.6C894.285 37.6 889.172 39.5067 884.925 43.32C880.765 47.1333 878.382 52.42 877.775 59.18H922.755Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M962.42 40.33C964.5 36.2566 967.447 33.0933 971.26 30.84C975.16 28.5866 979.883 27.46 985.43 27.46V39.68H982.31C969.05 39.68 962.42 46.8733 962.42 61.26V100H950.59V28.76H962.42V40.33Z"
        stroke="#E8EDDF"
        stroke-width="6"
        mask="url(#path-1-outside-1)"
      />
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="-0.0100098"
        y="0.799988"
        width="989"
        height="136"
        fill="black"
      >
        <rect
          fill="white"
          x="-0.0100098"
          y="0.799988"
          width="989"
          height="136"
        />
        <path d="M124.89 9.38998L98.63 100H85.37L64.31 27.07L42.47 100L29.34 100.13L3.98999 9.38998H16.6L36.36 86.22L58.2 9.38998H71.46L92.26 85.96L112.15 9.38998H124.89Z" />
        <path d="M202.836 61.65C202.836 63.9033 202.706 66.2867 202.446 68.8H145.506C145.939 75.82 148.323 81.3233 152.656 85.31C157.076 89.21 162.406 91.16 168.646 91.16C173.759 91.16 178.006 89.99 181.386 87.65C184.853 85.2233 187.279 82.0167 188.666 78.03H201.406C199.499 84.8767 195.686 90.4667 189.966 94.8C184.246 99.0467 177.139 101.17 168.646 101.17C161.886 101.17 155.819 99.6533 150.446 96.62C145.159 93.5866 140.999 89.2967 137.966 83.75C134.933 78.1167 133.416 71.6167 133.416 64.25C133.416 56.8833 134.889 50.4267 137.836 44.88C140.783 39.3333 144.899 35.0867 150.186 32.14C155.559 29.1067 161.713 27.59 168.646 27.59C175.406 27.59 181.386 29.0633 186.586 32.01C191.786 34.9567 195.773 39.03 198.546 44.23C201.406 49.3433 202.836 55.15 202.836 61.65ZM190.616 59.18C190.616 54.6733 189.619 50.8166 187.626 47.61C185.633 44.3166 182.903 41.8467 179.436 40.2C176.056 38.4667 172.286 37.6 168.126 37.6C162.146 37.6 157.033 39.5067 152.786 43.32C148.626 47.1333 146.243 52.42 145.636 59.18H190.616Z" />
        <path d="M230.281 42.02C232.708 37.7733 236.261 34.3066 240.941 31.62C245.621 28.9333 250.951 27.59 256.931 27.59C263.345 27.59 269.108 29.1067 274.221 32.14C279.335 35.1733 283.365 39.4633 286.311 45.01C289.258 50.47 290.731 56.84 290.731 64.12C290.731 71.3133 289.258 77.7267 286.311 83.36C283.365 88.9933 279.291 93.37 274.091 96.49C268.978 99.61 263.258 101.17 256.931 101.17C250.778 101.17 245.361 99.8267 240.681 97.14C236.088 94.4533 232.621 91.03 230.281 86.87V100H218.451V3.79999H230.281V42.02ZM278.641 64.12C278.641 58.7466 277.558 54.0667 275.391 50.08C273.225 46.0933 270.278 43.06 266.551 40.98C262.911 38.9 258.881 37.86 254.461 37.86C250.128 37.86 246.098 38.9433 242.371 41.11C238.731 43.19 235.785 46.2667 233.531 50.34C231.365 54.3267 230.281 58.9633 230.281 64.25C230.281 69.6233 231.365 74.3466 233.531 78.42C235.785 82.4066 238.731 85.4833 242.371 87.65C246.098 89.73 250.128 90.77 254.461 90.77C258.881 90.77 262.911 89.73 266.551 87.65C270.278 85.4833 273.225 82.4066 275.391 78.42C277.558 74.3466 278.641 69.58 278.641 64.12Z" />
        <path d="M369.171 9.38998C379.051 9.38998 387.588 11.2533 394.781 14.98C402.061 18.62 407.608 23.8633 411.421 30.71C415.321 37.5566 417.271 45.6167 417.271 54.89C417.271 64.1633 415.321 72.2233 411.421 79.07C407.608 85.83 402.061 91.03 394.781 94.67C387.588 98.2233 379.051 100 369.171 100H340.961V9.38998H369.171ZM369.171 90.25C380.871 90.25 389.798 87.1733 395.951 81.02C402.105 74.78 405.181 66.07 405.181 54.89C405.181 43.6233 402.061 34.8267 395.821 28.5C389.668 22.1733 380.785 19.01 369.171 19.01H352.791V90.25H369.171Z" />
        <path d="M497.875 61.65C497.875 63.9033 497.745 66.2867 497.485 68.8H440.545C440.979 75.82 443.362 81.3233 447.695 85.31C452.115 89.21 457.445 91.16 463.685 91.16C468.799 91.16 473.045 89.99 476.425 87.65C479.892 85.2233 482.319 82.0167 483.705 78.03H496.445C494.539 84.8767 490.725 90.4667 485.005 94.8C479.285 99.0467 472.179 101.17 463.685 101.17C456.925 101.17 450.859 99.6533 445.485 96.62C440.199 93.5866 436.039 89.2967 433.005 83.75C429.972 78.1167 428.455 71.6167 428.455 64.25C428.455 56.8833 429.929 50.4267 432.875 44.88C435.822 39.3333 439.939 35.0867 445.225 32.14C450.599 29.1067 456.752 27.59 463.685 27.59C470.445 27.59 476.425 29.0633 481.625 32.01C486.825 34.9567 490.812 39.03 493.585 44.23C496.445 49.3433 497.875 55.15 497.875 61.65ZM485.655 59.18C485.655 54.6733 484.659 50.8166 482.665 47.61C480.672 44.3166 477.942 41.8467 474.475 40.2C471.095 38.4667 467.325 37.6 463.165 37.6C457.185 37.6 452.072 39.5067 447.825 43.32C443.665 47.1333 441.282 52.42 440.675 59.18H485.655Z" />
        <path d="M540.01 89.08L562.11 28.76H574.72L546.77 100H532.99L505.04 28.76H517.78L540.01 89.08Z" />
        <path d="M651.362 61.65C651.362 63.9033 651.232 66.2867 650.972 68.8H594.032C594.465 75.82 596.848 81.3233 601.182 85.31C605.602 89.21 610.932 91.16 617.172 91.16C622.285 91.16 626.532 89.99 629.912 87.65C633.378 85.2233 635.805 82.0167 637.192 78.03H649.932C648.025 84.8767 644.212 90.4667 638.492 94.8C632.772 99.0467 625.665 101.17 617.172 101.17C610.412 101.17 604.345 99.6533 598.972 96.62C593.685 93.5866 589.525 89.2967 586.492 83.75C583.458 78.1167 581.942 71.6167 581.942 64.25C581.942 56.8833 583.415 50.4267 586.362 44.88C589.308 39.3333 593.425 35.0867 598.712 32.14C604.085 29.1067 610.238 27.59 617.172 27.59C623.932 27.59 629.912 29.0633 635.112 32.01C640.312 34.9567 644.298 39.03 647.072 44.23C649.932 49.3433 651.362 55.15 651.362 61.65ZM639.142 59.18C639.142 54.6733 638.145 50.8166 636.152 47.61C634.158 44.3166 631.428 41.8467 627.962 40.2C624.582 38.4667 620.812 37.6 616.652 37.6C610.672 37.6 605.558 39.5067 601.312 43.32C597.152 47.1333 594.768 52.42 594.162 59.18H639.142Z" />
        <path d="M678.807 3.79999V100H666.977V3.79999H678.807Z" />
        <path d="M730.039 101.17C723.366 101.17 717.299 99.6533 711.839 96.62C706.466 93.5866 702.219 89.2967 699.099 83.75C696.066 78.1167 694.549 71.6167 694.549 64.25C694.549 56.97 696.109 50.5567 699.229 45.01C702.436 39.3767 706.769 35.0867 712.229 32.14C717.689 29.1067 723.799 27.59 730.559 27.59C737.319 27.59 743.429 29.1067 748.889 32.14C754.349 35.0867 758.639 39.3333 761.759 44.88C764.966 50.4267 766.569 56.8833 766.569 64.25C766.569 71.6167 764.922 78.1167 761.629 83.75C758.422 89.2967 754.046 93.5866 748.499 96.62C742.952 99.6533 736.799 101.17 730.039 101.17ZM730.039 90.77C734.286 90.77 738.272 89.7733 741.999 87.78C745.726 85.7867 748.716 82.7966 750.969 78.81C753.309 74.8233 754.479 69.97 754.479 64.25C754.479 58.53 753.352 53.6767 751.099 49.69C748.846 45.7033 745.899 42.7567 742.259 40.85C738.619 38.8567 734.676 37.86 730.429 37.86C726.096 37.86 722.109 38.8567 718.469 40.85C714.916 42.7567 712.056 45.7033 709.889 49.69C707.722 53.6767 706.639 58.53 706.639 64.25C706.639 70.0567 707.679 74.9533 709.759 78.94C711.926 82.9267 714.786 85.9167 718.339 87.91C721.892 89.8167 725.792 90.77 730.039 90.77Z" />
        <path d="M793.953 41.89C796.293 37.8167 799.76 34.4366 804.353 31.75C809.033 28.9766 814.45 27.59 820.603 27.59C826.93 27.59 832.65 29.1067 837.763 32.14C842.963 35.1733 847.037 39.4633 849.983 45.01C852.93 50.47 854.403 56.84 854.403 64.12C854.403 71.3133 852.93 77.7267 849.983 83.36C847.037 88.9933 842.963 93.37 837.763 96.49C832.65 99.61 826.93 101.17 820.603 101.17C814.537 101.17 809.163 99.8267 804.483 97.14C799.89 94.3667 796.38 90.9433 793.953 86.87V133.8H782.123V28.76H793.953V41.89ZM842.313 64.12C842.313 58.7466 841.23 54.0667 839.063 50.08C836.897 46.0933 833.95 43.06 830.223 40.98C826.583 38.9 822.553 37.86 818.133 37.86C813.8 37.86 809.77 38.9433 806.043 41.11C802.403 43.19 799.457 46.2667 797.203 50.34C795.037 54.3267 793.953 58.9633 793.953 64.25C793.953 69.6233 795.037 74.3466 797.203 78.42C799.457 82.4066 802.403 85.4833 806.043 87.65C809.77 89.73 813.8 90.77 818.133 90.77C822.553 90.77 826.583 89.73 830.223 87.65C833.95 85.4833 836.897 82.4066 839.063 78.42C841.23 74.3466 842.313 69.58 842.313 64.12Z" />
        <path d="M934.975 61.65C934.975 63.9033 934.845 66.2867 934.585 68.8H877.645C878.078 75.82 880.462 81.3233 884.795 85.31C889.215 89.21 894.545 91.16 900.785 91.16C905.898 91.16 910.145 89.99 913.525 87.65C916.992 85.2233 919.418 82.0167 920.805 78.03H933.545C931.638 84.8767 927.825 90.4667 922.105 94.8C916.385 99.0467 909.278 101.17 900.785 101.17C894.025 101.17 887.958 99.6533 882.585 96.62C877.298 93.5866 873.138 89.2967 870.105 83.75C867.072 78.1167 865.555 71.6167 865.555 64.25C865.555 56.8833 867.028 50.4267 869.975 44.88C872.922 39.3333 877.038 35.0867 882.325 32.14C887.698 29.1067 893.852 27.59 900.785 27.59C907.545 27.59 913.525 29.0633 918.725 32.01C923.925 34.9567 927.912 39.03 930.685 44.23C933.545 49.3433 934.975 55.15 934.975 61.65ZM922.755 59.18C922.755 54.6733 921.758 50.8166 919.765 47.61C917.771 44.3166 915.041 41.8467 911.575 40.2C908.195 38.4667 904.425 37.6 900.265 37.6C894.285 37.6 889.172 39.5067 884.925 43.32C880.765 47.1333 878.382 52.42 877.775 59.18H922.755Z" />
        <path d="M962.42 40.33C964.5 36.2566 967.447 33.0933 971.26 30.84C975.16 28.5866 979.883 27.46 985.43 27.46V39.68H982.31C969.05 39.68 962.42 46.8733 962.42 61.26V100H950.59V28.76H962.42V40.33Z" />
      </mask>
    </Animation>
  );
}
