import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: 50px;
  height: 50px;
`;

export default function XingIcon() {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      xmlnsxlink="http://www.w3.org/1999/xlink"
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      width="512px"
      height="512px"
      viewBox="0 0 97.75 97.75"
      // style="enable-background:new 0 0 97.75 97.75;"
      xmlspace="preserve"
    >
      <g>
        <path
          d="M48.875,0C21.883,0,0,21.882,0,48.875S21.883,97.75,48.875,97.75S97.75,75.868,97.75,48.875S75.867,0,48.875,0z    M31.231,62.516h-9.806c-0.591,0-1.032-0.266-1.28-0.67c-0.26-0.418-0.273-0.962,0-1.503l10.416-18.385   c0.012-0.021,0.012-0.033,0-0.056l-6.629-11.477c-0.273-0.546-0.314-1.085-0.056-1.504c0.25-0.405,0.746-0.612,1.337-0.612h9.806   c1.504,0,2.241,0.969,2.729,1.838c0,0,6.701,11.689,6.741,11.755c-0.396,0.7-10.584,18.719-10.584,18.719   C33.403,61.525,32.697,62.516,31.231,62.516z M77.596,17.022L55.887,55.406c-0.015,0.021-0.015,0.045,0,0.063l13.821,25.255   c0.272,0.545,0.28,1.098,0.021,1.516c-0.248,0.402-0.711,0.627-1.302,0.627h-9.794c-1.502,0-2.253-0.999-2.738-1.867   c0,0-13.896-25.493-13.932-25.561C42.657,54.21,63.779,16.75,63.779,16.75c0.525-0.944,1.162-1.867,2.625-1.867h9.906   c0.59,0,1.053,0.223,1.303,0.626C77.874,15.928,77.866,16.48,77.596,17.022z"
          fill="#F5CB5C"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </Icon>
  );
}
