import React from "react";
import styled from "styled-components";
import AboutIcon from "./SVGs/AboutIcon";
import Frame from "./Frame";

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30% !important;
`;

const Skillframe = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 10px;
`;

const Skill = styled.h4`
  border: 1px solid ${props => props.theme.dark};
  border-radius: 10px 0px 10px 0px;
  background-color: ${props => props.theme.highlight};
  color: ${props => props.theme.dark};
  padding: 5px;
  margin: 5px;
`;
export default function About({ id }) {
  return (
    <>
      <Container id={id}>
        <AboutIcon />

        <Frame>
          <p>
            Hi, I am Lena. I'm a web developer, specialized in frontend and
            enjoying design and UX. I recently graduated from a web development
            bootcamp, which finalized a career switch from social media
            marketing to software. Below you will find examples of my projects
            and how to contact me. Enjoy!
          </p>
          <Skillframe>
            <p>These are my favourite technologies to work with:</p>

            <Skill>JavaScript</Skill>
            <Skill>HTML</Skill>
            <Skill>CSS</Skill>
            <Skill>React</Skill>
            <Skill>Bootstrap</Skill>
            <Skill>Sass</Skill>
            <Skill>Express.js</Skill>
            <Skill>Shell</Skill>
            <Skill>Git</Skill>
            <Skill>And more…</Skill>
          </Skillframe>
        </Frame>
      </Container>
    </>
  );
}
