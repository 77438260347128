import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 10vh;
  color: ${props => props.theme.highlight};
  justify-content: center;
`;
const MenuButtonWide = styled.a`
  display: flex;
  width: 290px;
  height: 50px;
  background: ${props => props.theme.dark};
  margin: 20px;
  border: 2px solid ${props => props.theme.highlight};
  color: ${props => props.theme.text};
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 10px 0px;
  text-decoration: none;
`;

export default function Menu() {
  return (
    <>
      <Container>
        <MenuButtonWide href="#about">
          <h1>About</h1>
        </MenuButtonWide>
        <MenuButtonWide href="#code">
          <h1>Projects</h1>
        </MenuButtonWide>
        <MenuButtonWide href="#contact">
          <h1>Contact</h1>
        </MenuButtonWide>
      </Container>
    </>
  );
}
