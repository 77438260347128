import React from "react";
import styled from "styled-components";
const FrameStyle = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 500px;
  padding: 20px;
  line-height: 2;
  background: ${props => props.theme.dark};
  margin: 20px;
  border: 2px solid ${props => props.theme.highlight};
  color: ${props => props.theme.text};
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 10px 0px;
`;

export default function Frame({ children }) {
  return <FrameStyle>{children}</FrameStyle>;
}
